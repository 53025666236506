<template>
  <!-- 人员明细 -->
  <div>
    <div class="personnelDetails_main">
      <van-nav-bar left-text="人员明细"
                   left-arrow
                   @click-left="onClickLeft"
                   :fixed="true">
        <template #right>
          <div class="date">
            <i class="fa fa-calendar"></i>
            {{yymmdd}}
          </div>
        </template>
      </van-nav-bar>
      <div class="content">
        <!-- 人员明细 -->
        <div class="his_content">
          <van-search v-model="searchStr"
                      shape="round"
                      placeholder="请输入工号/姓名"
                      @search="onSearch" />
          <div class="table_content">
            <div class="tools_title">
              <div class="left">
                <img :src="require('@/assets/img/job_examine05.png')"
                     alt />
                <div>{{linename}}</div>
              </div>
              <div class="right">
                <van-button class="btn1"
                            type="info"
                            size="small"
                            @click="goToAdd">增加</van-button>
                <van-button type="danger"
                            size="small"
                            @click="goToDelete">删除</van-button>
              </div>
            </div>
            <div class="table_box">
              <table ref="table"
                     class="table">
                <!-- 表头 -->
                <tr class="top">
                  <th v-for="(item_th, index_th) in auditThList"
                      :key="index_th">
                    <!-- :style="{ width: item_th.gridwith * 1.5 + 'px' }" -->
                    <div class="title"
                         :style="item_th.gridwith? {width: item_th.gridwith * 1.5 + 'px'}:'min-width:90px' ">
                      {{ item_th.chnname }}
                    </div>
                  </th>
                </tr>
                <!--展示列表数据 -->
                <tr class="cont"
                    v-for="(item_tr, index_tr) in empList"
                    :key="index_tr">
                  <td v-for="(item_td, index_td) in auditThList"
                      :key="index_td">
                    <div class="comm_box">
                      <div class="boolean_box"
                           v-if="item_td.datatype == 'checkbox'">
                        <van-checkbox v-model="item_tr[item_td.fieldname]"></van-checkbox>
                      </div>
                      <div class="input_box"
                           v-else-if="item_td.datatype == 'num'">
                        <van-field v-model="item_tr[item_td.fieldname]"
                                   type="number"
                                   :placeholder="item_td.chnname" />
                      </div>
                      <div v-else>{{ item_tr[item_td.fieldname] }}</div>
                    </div>
                  </td>
                </tr>
                <tr class="nodata"
                    v-if="empList.length==0">
                  <td align="center"
                      colspan="6">暂无数据</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <van-button type="info"
                    @click="submitInfo">提交</van-button>
      </div>
    </div>

    <!-- 接口返回提示信息弹窗 -->
    <van-dialog v-model="showHintDia"
                class="Automatic_report hint_dialog"
                :show-confirm-button="false">
      <template #title>
        <div style="height:60px"></div>
      </template>
      <div class="content">
        <img :src="require(isSuccess?'@/assets/img/job_booking11.png':'@/assets/img/job_booking10.png')"
             alt />
        <div class="hint_msg">{{hintMessage}}</div>
        <van-button type="info"
                    size="small"
                    round
                    @click="showHintDia=false">好的</van-button>
      </div>
    </van-dialog>

    <!-- 选择产线人员弹窗 -->
    <PersonnelPop ref="refPersonnelPop"
                  :single="false"
                  @getPersonnel="getPersonnel"></PersonnelPop>

  </div>
</template>

<script>
import { Toast, Notify, Dialog } from "vant";
import { getWorkTimeEmpList, postWorkTimeDetail, batchAddWorkTimeEmpDetail, batchDeleteWorkTimeEmpDetail } from '@api/wxjj.js'
import PersonnelPop from "@components/selectPersonnel/index.vue";
export default {
  components: {
    PersonnelPop
  },
  data () {
    const userInfo = localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {}
    let autoid = this.$route.query.autoid
    let linename = this.$route.query.linename
    let yymmdd = this.$route.query.yymmdd
    return {
      userInfo,
      autoid,
      linename,
      yymmdd,
      empList: [],
      initempList: [],
      auditThList: [
        {
          fieldname: "checked",
          chnname: "",
          gridwith: 20,
          datatype: 'checkbox'
        },
        {
          fieldname: "sqNum",
          chnname: "序号",
          gridwith: 20,
          datatype: 'str'
        },
        {
          fieldname: "empcode",
          chnname: "工号",
          gridwith: 30,
          datatype: 'str'
        },
        {
          fieldname: "empname",
          chnname: "姓名",
          gridwith: 60,
          datatype: 'str'
        },
        {
          fieldname: "worktimes",
          chnname: "时数",
          gridwith: 50,
          datatype: 'num'
        },
        {
          fieldname: "overtimes",
          chnname: "加班工时",
          gridwith: 50,
          datatype: 'num'
        },
        {
          fieldname: "qjtimes",
          chnname: "请假工时",
          gridwith: 50,
          datatype: 'num'
        },
        {
          fieldname: "kgtimes",
          chnname: "旷工工时",
          gridwith: 50,
          datatype: 'num'
        }
      ],
      showHintDia: false,
      isSuccess: true,
      hintMessage: '',
      searchStr: '',

    }
  },

  created () {
    this.autoid = this.$route.query.autoid
    this.linename = this.$route.query.linename
    this.yymmdd = this.$route.query.yymmdd
    this.getWorkTimeEmpListData()
  },
  methods: {
    // 选择带班人员回调
    getPersonnel (data) {
      const ids = data.map(it => { return it.empid }) || []
      let params = {
        mautoid: this.autoid,
        empidlist: ids.join(','),
        username: this.userInfo.username
      }
      batchAddWorkTimeEmpDetail(params).then(res => {
        if (res.data[0].info)
        {
          Toast.fail(res.data[0].info);
        } else
        {
          Toast.success(this.$t('module.createSuc'));
          this.getWorkTimeEmpListData()
        }
      })
    },
    // 增加人员
    goToAdd () {
      this.$refs.refPersonnelPop.showPersonnelPop = true
    },
    // 删除
    goToDelete () {
      const checkedData = this.empList.filter(it => { return it.checked })
      if (checkedData.length == 0)
      {
        Toast.fail('请选择记录！');
        return
      }
      const ids = checkedData.map(it => { return it.autoid }) || []
      let params = {
        autoidlist: ids.join(','),
        username: this.userInfo.username
      }
      Dialog.confirm({
        title: this.$t('module.systemhint'),
        message: this.$t('module.RUSureTtoDeleteTheSelected'),
      }).then(() => {
        batchDeleteWorkTimeEmpDetail(params).then(res => {
          if (res.data[0].info)
          {
            Toast.fail(res.data[0].info);
          } else
          {
            Toast.success(this.$t('module.DelSuc'));
            this.getWorkTimeEmpListData()
          }
        })
      }).catch(() => {
        // on cancel
      });
    },
    // 回退
    onClickLeft () {
      this.$router.push('/workingHours')
    },
    // 提交按钮
    submitInfo () {
      postWorkTimeDetail({
        username: this.userInfo.username,
        data: this.empList
      }).then(res => {
        this.showHintDia = true
        if (res.data[0].info == '')
        {
          this.isSuccess = true
          this.hintMessage = '提交工时成功！'
        } else
        {
          this.isSuccess = false
          this.hintMessage = res.data[0].info
        }
        this.getWorkTimeEmpListData()
      })
    },
    // 人员搜索方法
    onSearch () {
      if (this.searchStr)
      {
        this.empList = this.initempList.filter(e => {
          const empcode = e.empcode ? e.empcode : ''
          const empname = e.empname ? e.empname : ''
          return (
            empcode.indexOf(this.searchStr) > -1 ||
            empname.indexOf(this.searchStr) > -1
          )
        })
      } else
      {
        this.getWorkTimeEmpListData()
      }
    },
    // 获取人员明细
    getWorkTimeEmpListData () {
      getWorkTimeEmpList({
        autoid: this.autoid,
        username: this.userInfo.username
      }).then(res => {
        let result = JSON.parse(JSON.stringify(res.data))
        result.forEach((ele, idx) => {
          ele.sqNum = idx + 1
          ele.checked = false
        });
        this.empList = result
        this.initempList = result
      })
    }
  }

}
</script>

<style scoped lang="less">
.hint_dialog {
  /deep/.van-dialog__header {
    padding: 20px 0;
    background-image: url("~@/assets/img/job_examine08.png");
    background-repeat: no-repeat;
    background-size: 100%;
    color: #08306b;
    .title {
      font-size: 40px;
      font-weight: bold;
      margin-bottom: 10px;
    }
  }
  .content {
    text-align: center;
    img {
      width: 160px;
      height: 160px;
    }
    .hint_msg {
      margin-top: 20px;
      font-size: 32px;
      font-weight: bold;
    }
    .van-button {
      margin: 40px 0;
      width: 240px;
      font-size: 28px;
    }
  }
}
.personnelDetails_main {
  height: 100vh;
  background-image: url("~@/assets/img/job_examine01.png");
  overflow: hidden;
  background-color: #eaedef;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position-y: 1.22667rem;
  .van-nav-bar {
    height: 92px;
    background: #bcdfff;
    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #333;
        font-size: 0.5rem;
      }
      .van-nav-bar__text {
        color: #333;
        font-size: 36px;
        font-weight: bold;
      }
    }
    /deep/.van-nav-bar__title {
      color: #333;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
    /deep/.van-nav-bar__right {
      .date {
        font-size: 28px;
        display: flex;
        align-items: center;
        .fa-calendar {
          margin-right: 6px;
        }
      }
    }
  }
  .van-hairline--bottom::after {
    border: unset;
  }
  .content {
    height: calc(100% - 192px);
    margin-top: 92px;
    overflow-y: auto;
    .Audit_content {
      .tools_row {
        padding: 0 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .van-cell {
          border: 2px solid #d2d2d2;
          border-radius: 10px;
          padding: 10px 20px;
        }
        .van-button {
          width: 200px;
          font-size: 14px;
          .van-icon {
            font-size: 14px;
          }
        }
      }
    }
    .his_content {
      .van-search {
        background-color: unset;
        padding-bottom: 0;
      }
    }
    .table_content {
      margin: 20px;
      padding: 10px;
      border-radius: 10px;
      background: #ffffff;
      .tools_title {
        margin-top: 10px;
        margin-bottom: 15px;
        display: flex;
        justify-content: space-between;
        .left {
          display: flex;
          align-items: center;
          font-size: 32px;
          font-weight: bold;
          img {
            width: 32px;
            height: 32px;
            margin-right: 10px;
          }
        }
        .right {
          display: flex;
          .btn1 {
            margin-right: 16px;
          }
          .van-button {
            padding: 0 20px;
            height: 60px;
          }
        }
      }
      .table_box {
        overflow: auto;
        .table {
          width: 100%;
          border-spacing: 0px;
          tr {
            td:nth-child(1) {
              border-left: 2px solid #dcdfe6;
            }
            th,
            td {
              padding: 6px 10px;
              text-align: center;
              border-bottom: 2px solid #dcdfe6;
              border-right: 2px solid #dcdfe6;
              .comm_box {
                display: flex;
                justify-content: center;
                .boolean_box {
                  .van-checkbox {
                    justify-content: center;
                    /deep/.van-checkbox__icon--checked {
                      .van-icon {
                        color: #fff !important;
                        background-color: #1989fa !important;
                        border-color: #1989fa !important;
                      }
                    }
                    /deep/.van-checkbox__icon--disabled .van-icon {
                      background-color: #fff;
                    }
                  }
                }
                .input_box {
                  width: 150px;
                  .van-cell {
                    font-size: 24px;
                    border: 2px solid #d2d2d2;
                    border-radius: 10px;
                    padding: 8px 16px;
                  }
                }
              }
            }
          }
          .top {
            position: sticky;
            top: 0;
            font-size: 28px;
            background: #e9f3fd;
            height: 64px;
            th:nth-child(1) {
              border-left: 2px solid #dcdfe6;
            }
            th {
              border-top: 2px solid #dcdfe6;
            }
          }
          .cont {
            height: 64px;
            font-size: 24px;
          }
          .cont:nth-child(2n-1) {
            background: #f5f5f5;
          }
          .nodata {
            td {
              font-size: 28px;
            }
          }
        }
      }
    }
  }
  .footer {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    .van-button {
      width: 90%;
      height: 80%;
      border-radius: 10px;
    }
  }
}
</style>